import axios from "axios";

const base_url = process.env.REACT_APP_BASE_URL;

export const getBaseUrl = () => {
    return base_url;
}

export const getDistrictsHavingAllPoints = async () => {
    try {
        const response = await axios.get(base_url + 'api/districts/all-points')
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getDistrictsHavingShops = async () => {
    try {
        const response = await axios.get(base_url + 'api/districts/shops')
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getDistrictsHavingMTOs = async () => {
    try {
        const response = await axios.get(base_url + 'api/districts/mtos')
        return response.data;
    }
    catch (error){
        throw error;
    }
}
export const getVillagesHavingAllPoints = async (districtId) => {
    try {
        const response = await axios.get(base_url + 'api/villages/all-points/' + districtId)
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getVillagesHavingShops = async (districtId) => {
    try {
        const response = await axios.get(base_url + 'api/villages/shops/' + districtId)
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getVillagesHavingMTOs = async (districtId) => {
    try {
        const response = await axios.get(base_url + 'api/villages/mtos/' + districtId)
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getDistrictsHavingEssnMTOs = async () => {
    try {
        const response = await axios.get(base_url + 'api/districts/essn')
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getVillagesHavingEssnMTOs = async (districtId) => {
    try {
        const response = await axios.get(base_url + 'api/villages/essn/' + districtId)
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getDistrictsHavingValidationSites = async () => {
    try {
        const response = await axios.get(base_url + 'api/districts/sites')
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getVillagesHavingValidationSites = async (districtId) => {
    try {
        const response = await axios.get(base_url + 'api/villages/sites/' + districtId)
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getDistrictsHavingAtms = async () => {
    try {
        const response = await axios.get(base_url + 'api/districts/atms')
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getVillagesHavingAtms = async (districtId) => {
    try {
        const response = await axios.get(base_url + 'api/villages/atms/' + districtId)
        return response.data;
    }
    catch (error){
        throw error;
    }
}

export const getAllPoints = async (page = null, search = "") => {
    try {
        let url = base_url + 'api/redemption-points';
        
        const params = new URLSearchParams();
        if (page) params.append("page", page);
        if (search) params.append("search", search);

        const response = await axios.get(`${url}?${params.toString()}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllPointsByDistrict = async (districtId, page = null, search = "") => {
    try {
        let url = `${base_url}api/redemption-points/district/${districtId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllPointsByVillage = async (villageId, page = null, search = "") => {
    try {
        let url = `${base_url}api/redemption-points/village/${villageId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllShops = async (page = null, search = "") => {
    try {
        let url = base_url + 'api/shops';
        
        const params = new URLSearchParams();
        if (page) params.append("page", page);
        if (search) params.append("search", search);

        const response = await axios.get(`${url}?${params.toString()}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getShopByDistrict = async (districtId, page = null, search = "") => {
    try {
        let url = `${base_url}api/shops/district/${districtId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getShopByVillage = async (villageId, page = null, search = "") => {
    try {
        let url = `${base_url}api/shops/village/${villageId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllMtos = async (page = null, search = "") => {
    try {
        let url = base_url + 'api/money-transfer-operators';
        
        const params = new URLSearchParams();
        if (page) params.append("page", page);
        if (search) params.append("search", search);

        const response = await axios.get(`${url}?${params.toString()}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getMtosByDistrict = async (districtId, page = null, search = "") => {
    try {
        let url = `${base_url}api/money-transfer-operators/district/${districtId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getMtosByVillage = async (villageId, page = null, search = "") => {
    try {
        let url = `${base_url}api/money-transfer-operators/village/${villageId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllEssnMtos = async (page = null, search = "") => {
    try {
        let url = base_url + 'api/essn-money-transfer-operators';
        
        const params = new URLSearchParams();
        if (page) params.append("page", page);
        if (search) params.append("search", search);

        const response = await axios.get(`${url}?${params.toString()}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getEssnMtosByDistrict = async (districtId, page = null, search = "") => {
    try {
        let url = `${base_url}api/essn-money-transfer-operators/district/${districtId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getEssnMtosByVillage = async (villageId, page = null, search = "") => {
    try {
        let url = `${base_url}api/essn-money-transfer-operators/village/${villageId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllSites = async (page = null, search = "") => {
    try {
        let url = base_url + 'api/sites';
        
        const params = new URLSearchParams();
        if (page) params.append("page", page);
        if (search) params.append("search", search);

        const response = await axios.get(`${url}?${params.toString()}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSitesByDistrict = async (districtId, page = null, search = "") => {
    try {
        let url = `${base_url}api/sites/district/${districtId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSitesByVillage = async (villageId, page = null, search = "") => {
    try {
        let url = `${base_url}api/sites/village/${villageId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllAtms = async (page = null, search = "") => {
    try {
        let url = base_url + 'api/atms';
        
        const params = new URLSearchParams();
        if (page) params.append("page", page);
        if (search) params.append("search", search);

        const response = await axios.get(`${url}?${params.toString()}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAtmByDistrict = async (districtId, page = null, search = "") => {
    try {
        let url = `${base_url}api/atms/district/${districtId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAtmByVillage = async (villageId, page = null, search = "") => {
    try {
        let url = `${base_url}api/atms/village/${villageId}`;
        
        let params = [];
        if (page) params.push(`page=${page}`);
        if (search) params.push(`search=${encodeURIComponent(search)}`);

        if (params.length) url += "?" + params.join("&");

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};