import React, { useEffect, useState } from "react";
import "../Common/Styling/Display.css";
import { getAllMtos, getMtosByDistrict, getMtosByVillage, getBaseUrl } from "../../api";
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactGA from "react-ga4";
import phoneIcon from "../../images/phoneIcon.png";
import locationIcon from "../../images/locationIcon.png";
import openingIcon from "../../images/openingIcon.png";
import Accordion from 'react-bootstrap/Accordion';

export default function MTOs(props) {
  
  const {usedLanguage} = props;
  const [mtos, setMtos] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalMTOs, setTotalMTOs] = useState(0);
  
  const fetchData = async (reset) => {
    try {
      
      let page = 1;

      if(!reset) {
        page = currentPage;
      }

      let data = [];

      let mtosList = [];

      if(props.districtCode && !props.villagePcode) {
        mtosList = await getMtosByDistrict(props.districtCode, page, props.searchQuery);
      } else if(props.villagePcode) {
        mtosList = await getMtosByVillage(props.villagePcode, page, props.searchQuery);
      } else {
        mtosList = await getAllMtos(page, props.searchQuery);
      }

      if(mtosList.last_page > page)  {
        setCurrentPage(page + 1);
      } else {
        setHasMoreData(false);
      }

      if(reset) {
        data = mtosList.data;
      } else {
        data = [...mtos, ...mtosList.data];
      }

      setTotalMTOs(mtosList.total);
      setMtos(data);
      
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);

    fetchData(true);
  }, [props.districtCode, props.villagePcode, props.searchQuery]);

  function getTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale.locale === locale);
    return translation ? translation[field] : '';
  }

  function getAdminAreaTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  const lengthStatement = `${props.statement} ${totalMTOs}`;

  const handleLinkClick = (event, latitude, longitude) => {
  
    ReactGA.event({
        category: "MTOs Users", 
        action: "Clicked an MTO Location", 
        label: `Latitude: ${latitude}, Longitude: ${longitude}`, 
    });

  };

  return (
    <div className="container">
      <div className="container-sub">
        <h5 className='num'>{lengthStatement}</h5>
        <InfiniteScroll
          dataLength={mtos.length}
          next={fetchData}
          hasMore={hasMoreData}
          loader={<p className="load" >{props.loading}</p>}
          endMessage={<p className="load" >{props.dataLoad}</p>}
        >

          <Row className="rowStyle">
            {mtos?.map((mto, index) => (
              <Accordion className="col-md-5">
                <Accordion.Item eventKey="0" className="cardStyle">
                    <Accordion.Header>
                      <Row className="cardHeader" >
                        <Col  xs={3} className="p-0">
                          <img src={getBaseUrl() + "storage/" + mto.image_path} className="img" alt="mto"/>
                        </Col>
                        <Col xs={9} className="p-0">
                          <h5 className="name">{getTranslation(mto.translations, usedLanguage, 'name')}</h5>
                        </Col>
                      </Row>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="details">
                          <img alt="" src={locationIcon} className="icon"/>
                          {getAdminAreaTranslation(mto.district.translations, usedLanguage, 'name')}{usedLanguage === 'en' ? ',' : '،' }
                          {getAdminAreaTranslation(mto.village.translations, usedLanguage, 'name')}{usedLanguage === 'en' ? ',' : '،' }
                          {getTranslation(mto.translations, usedLanguage, 'address')}
                        </p>
                        <p className="details"><img alt="" src={openingIcon} className="icon"/>
                          {getTranslation(mto.translations, usedLanguage, 'opening_days')}
                        </p>                      
                        <p className="details"><img alt="" src={phoneIcon} className="icon"/>{mto.phone_number ? mto.phone_number : '-'}</p>
                    
                      <a  href={`https://www.google.com/maps/search/?api=1&query=${mto.latitude},${mto.longitude}`} 
                          target="_blank"
                          onClick={(e) => handleLinkClick(e, mto.latitude, mto.longitude)}
                      >
                        <div className="cardFooter">
                          <h6 className="buttonText">
                            {props.location}
                          </h6>
                        </div>
                    </a>
                    </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))} 
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
}
