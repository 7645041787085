import React, { useEffect, useState } from "react";
import "../Common/Styling/Display.css";
import { getAllPoints, getAllPointsByDistrict, getAllPointsByVillage, getBaseUrl } from "../../api";
import InfiniteScroll from 'react-infinite-scroll-component';
import disabilityIcon from "../../images/disabilityIcon.png";
import phoneIcon from "../../images/phoneIcon.png";
import shopPromotion from "../../images/shopPromotion-img.png";
import locationIcon from "../../images/locationIcon.png";
import openingIcon from "../../images/openingIcon.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactGA from "react-ga4";
import Accordion from 'react-bootstrap/Accordion';
import { all } from "axios";

export default function AllRedemptionPoints(props) {
  
  const {usedLanguage} = props;
  const [allPoints, setAllPoints] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAllPoints, setTotalAllPoints] = useState(0);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);

  const isRTL = document.documentElement.dir === "rtl";
  
  const fetchData = async (reset) => {
    try {
      
      let page = 1;

      if(!reset) {
        page = currentPage;
      }

      let data = [];

      let allPointsList = [];

      if(props.districtCode && !props.villagePcode) {
        allPointsList = await getAllPointsByDistrict(props.districtCode, page, props.searchQuery);
      } else if(props.villagePcode) {
        allPointsList = await getAllPointsByVillage(props.villagePcode, page, props.searchQuery);
      } else {
        allPointsList = await getAllPoints(page, props.searchQuery);
      }

      if(allPointsList.last_page > page)  {
        setCurrentPage(page + 1);
      } else {
        setHasMoreData(false);
      }

      if(reset) {
        data = allPointsList.data;
      } else {
        data = [...allPoints, ...allPointsList.data];
      }

      setTotalAllPoints(allPointsList.total);
      setAllPoints(data);

    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);

    fetchData(true);
  }, [props.districtCode, props.villagePcode, props.searchQuery]);

  const lengthStatement = `${props.statement} ${totalAllPoints}`;

  const handleLinkClick = (event, latitude, longitude) => {
  
    ReactGA.event({
        category: "AllPoints Users", 
        action: "Clicked a Location", 
        label: `Latitude: ${latitude}, Longitude: ${longitude}`, 
    });

  };

  const handleToggle = (currentId) => {
    currentActiveKey === currentId ? setCurrentActiveKey(null) : setCurrentActiveKey(currentId);
  }

  return (
    <div className="container">
      <div className="container-sub">
        <h5 className='num'>{lengthStatement}</h5>
        <InfiniteScroll
          dataLength={allPoints.length}
          next={fetchData}
          hasMore={hasMoreData}
          loader={<p className="load" >{props.loading}</p>}
          endMessage={<p className="load" >{props.dataLoad}</p>}
        >
        <Row className="rowStyle">
          {allPoints?.map((points, index) => (
              <Accordion className="col-md-5" key={points.id} activeKey={currentActiveKey}>
                <Accordion.Item eventKey={points.id} className="cardStyle">
                    <Accordion.Header onClick={() => handleToggle(points.id)}>
                      <Row className="cardHeader" >
                        <Col  xs={3} className="p-0">
                          <img 
                            src={points.promotions_en ? shopPromotion : getBaseUrl() + "storage/" + points.image_path} 
                            className="img" 
                            alt="points"
                          />
                        </Col>
                        <Col xs={9} className="p-0">
                          <h5 className="name">{usedLanguage === 'ar' ? points.name_ar : points.name_en }</h5>
                        </Col>
                      </Row>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="disabilityDiv">
                        <p className="details">
                          <img alt="" src={locationIcon} className="icon"/>
                          {usedLanguage === 'ar' ? points.district_ar : points.district_en }{usedLanguage === 'en' ? ',' : '،' }
                          {usedLanguage === 'ar' ? points.village_ar : points.village_en }{usedLanguage === 'en' ? ',' : '،' }
                          {usedLanguage === 'ar' ? points.address_ar : points.address_en }
                          {points.location_en && ` - ${usedLanguage === 'ar' ? points.location_ar : points.location_en}`}
                        </p>
                        {points.is_accessible === 1 && <img alt="" src={disabilityIcon} className="icon"/>}
                      </div>
                      {points.opening_days_ar && <p className="details"><img alt="" src={openingIcon} className="icon"/>{usedLanguage === 'ar' ? points.opening_days_ar : points.opening_days_en }</p>}
                      {points.working_hours_ar && <p className="details"><img alt="" src={openingIcon} className="icon"/>{usedLanguage === 'ar' ? points.working_hours_ar : points.working_hours_en }</p>}
                      <p className="details"><img alt="" src={phoneIcon} className="icon"/>{points.phone_number}</p>
                      
                      {points.promotions_en && points.promotions_en.length != 0 && (
                        <div className="promotionDiv">
                          <h7 className="popUpTitle">{props.popUpTitle}</h7>
                          {usedLanguage === 'ar' ? points.promotions_ar : points.promotions_en.map((promotion, promoIndex) => (
                            <Col key={promoIndex} className="promotionTxt">
                              <p>
                                {promotion}
                              </p>
                            </Col>
                          ))}
                        </div>
                      )}
                      <a  href={`https://www.google.com/maps/search/?api=1&query=${points.latitude},${points.longitude}`} 
                          target="_blank"
                          onClick={(e) => handleLinkClick(e, points.latitude, points.longitude)}
                      >
                        <div className="cardFooter">
                          <h6 className="buttonText">
                            {props.location}
                          </h6>
                        </div>
                    </a>
                    </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))} 
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
}
