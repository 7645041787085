import React, { useState, useEffect} from 'react';
import Select from 'react-select';
import "../Styling/Filter.css";
import "../Styling/Select.css";
import { useLocation, Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import shopIcon from "../../../images/shopIcon.png";
import mtoIcon from "../../../images/mtoIcon.png";
import atmIcon from "../../../images/atmIcon.png";

export default function Filter(props) {  
  const { usedLanguage } = props;
  const RedemptionPointName = props.name;

  const [searchQuery, setSearchQuery] = useState('');

  const [regions, setRegions] = useState([]);
  const [villages, setVillages] = useState([]);
  
  const [filterState, setFilterState] = useState({
    selectedRegion: { value: null, label: props.placeHolderName1 },
    selectedRegionId: null,
    selectedVillage: { value: null, label: props.placeHolderName2 },
    selectedVillageId: null,
  });

  const location = useLocation();
  const paths = ['/mtos', '/shops', '/atms', '/all-points'];

  useEffect(() => {
    setFilterState({
      selectedRegion: { value: null, label: props.placeHolderName1 },
      selectedRegionId: null,
      selectedVillage: { value: null, label: props.placeHolderName2 },
      selectedVillageId: null,
    });
  }, [props.placeHolderName1, props.placeHolderName2]);
  

  const [showRedemptionpoints, setShowRedemptionpoints] = useState(false);

  const handleChangeRegion = (region) => {
    if (region !== null) {
      setFilterState((prevState) => ({
        ...prevState,
        selectedRegion: region,
        selectedRegionId: region.value,
        selectedVillage: { value: null, label: props.placeHolderName2 },
        selectedVillageId: null,
      }));
    }
  };

  const handleChangeVillage = (village) => {
    if (village !== null) {
      setFilterState((prevState) => ({
        ...prevState,
        selectedVillage: village,
        selectedVillageId: village.value,
      }));
    }
  };

const getTranslation = (translations, locale) => {
  const translation = translations.find(translation => translation.locale === locale);
  return translation ? translation.name : '';
};

useEffect(() => {
  const fetchDistrictData = async () => {
    try {
      const districtData = await props.getDistricts();
      const Districts = districtData.map(district => ({
        value: district.code,
        label: getTranslation(district.translations, usedLanguage),
      }));
      setRegions([{ value: null, label: props.placeHolderName1 }, ...Districts]);
      setShowRedemptionpoints(true);

      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.size > 0) {
        const regionId = queryParams.get('region');
        const region = Districts.find(reg => reg.value === regionId);
        if (region) handleChangeRegion(region);
      }
    } catch (error) {
      console.error('Error fetching districts:', error);
    }
  };

  fetchDistrictData();
}, [usedLanguage, props.placeHolderName1]);

useEffect(() => {
  const fetchVillageData = async () => {
    try {
      if (filterState.selectedRegionId) {
        const villageData = await props.getVillages(filterState.selectedRegionId);
        const Villages = villageData.map(village => ({
          value: village.pcode,
          label: getTranslation(village.translations, usedLanguage),
        }));
        setVillages([{ value: null, label: props.placeHolderName2 }, ...Villages]);
      } else {
        setVillages([]);
      }
    } catch (error) {
      console.error('Error fetching villages:', error);
    }
  };

  fetchVillageData();
}, [filterState.selectedRegionId, usedLanguage, props.placeHolderName2]);

  return (
    <Container>
      <div className='inputDiv'>
        <Row className="row-spacing">
          <Col xs={12} md={12} className='column'>
            <input 
                type="text"
                placeholder={props.searchPlaceholder} 
                className='input'
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
            />
          </Col>
        </Row>
        <Row className="gy-2">
          <Col xs={12} md={6}>
            <Select
              classNamePrefix="custom-select"
              value={filterState.selectedRegion}
              onChange={handleChangeRegion}
              options={regions}
              isSearchable
              placeholder={props.placeHolderName1}
              menuPortalTarget={document.body}
            />
          </Col>      
          <Col xs={12} md={6}>
            <Select
              classNamePrefix="custom-select"
              value={filterState.selectedVillage}
              onChange={handleChangeVillage}
              options={villages}
              isSearchable
              placeholder={props.placeHolderName2}
              menuPortalTarget={document.body}
            />
          </Col>
        </Row>
      </div>
      {paths.includes(location.pathname) && (
        <div>
          <div className="divider">
            <h4>{props.dividerHeaderName}</h4>
          </div>
          <div className='iconsFilter'>
            <div>
              <Link to={'/shops'}>
                <img src={shopIcon} className='iconStyle'/>
              </Link>
              <h6 className='pageName'>{props.pageName1}</h6>
            </div>
            <div>
              <Link to="/mtos">
              <img src={mtoIcon} className='iconStyle'/>
              </Link>
            <h6 className='pageName'>{props.pageName2}</h6>
            </div>
            <div>
              <Link  to="/atms">
                <img src={atmIcon} className='iconStyle'/>
              </Link>
              <h6 className='pageName'>{props.pageName5}</h6>
            </div>
          </div>
        </div>
      )}
     <RedemptionPointName districtCode={filterState.selectedRegionId} 
          villagePcode={filterState.selectedVillageId}
          searchQuery={searchQuery}
          statement={props.statement} 
          usedLanguage={usedLanguage}
          dataLoad={props.dataLoad}
          loading={props.loading}
          location={props.location}
          bannerMessage={props.bannerMessage}
          popUpTitle={props.popUpTitle}
      />
    </Container>
  
  );
};

