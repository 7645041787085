import './App.css';
import NavBar from './components/Navbar';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useState, useEffect } from 'react';
import '@wfp/ui/src/globals/scss/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Filter from './components/Common/Components/Filter';
import { getDistrictsHavingAllPoints, getVillagesHavingAllPoints,
         getDistrictsHavingShops, getVillagesHavingShops, 
         getDistrictsHavingMTOs, getVillagesHavingMTOs,
         getDistrictsHavingEssnMTOs, getVillagesHavingEssnMTOs,
         getDistrictsHavingAtms, getVillagesHavingAtms,
         getDistrictsHavingValidationSites, getVillagesHavingValidationSites
       } from "./api";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AllRedemptionPoints from './components/RedemptionPoints/AllRedemptionPoints';
import Shops from './components/RedemptionPoints/Shops';
import MTOs from './components/RedemptionPoints/MTOs';
import ATMs from './components/RedemptionPoints/ATMs';
import Sites from './components/Sites';
import EssnMTOs from './components/EssnMTOs';
import { useLocation } from 'react-router-dom';
import './components/Common/Styling/Fonts.css';

function App() {

  const [lngUsed, setLngUsed]= useState("ar");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("ar");
    document.documentElement.dir = 'rtl';
    setLngUsed('ar');
  }, [i18n]);

  const lngs = {
    en: {nativeName: 'En' },
    ar: {nativeName: 'ع'}
  }

  function changeLanguage (lng) {
    i18next.changeLanguage(lng);
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.lang = lng;
    setLngUsed(lng)
  };

  const location = useLocation();
  
  return (
    <div className="App">
        <NavBar lngs={lngs}
                changeLanguage={changeLanguage}
                redemptionPointsHeaderName={t('redemptionPointsHeaderTitle')}
                shopHeaderName={t('shopHeaderTitle')}
                mtoHeaderName={t('mtoHeaderTitle')}
                essnMtoHaderName={t('essnMtoHeaderTitle')}
                sitesHeaderName={t('sitesHeaderTitle')}
                atmsHeaderName={t('atmHeaderTitle')}
                pageName={t('redemptionPointsPage')}
                pageName1={t('shopPage')}
                pageName2={t('mtoPage')}
                pageName3={t('essnMtoPage')}
                pageName4={t('sitesPage')}
                pageName5={t('atmPage')}
        />
        <Routes>
          <Route path="/" element={<Navigate  to="/all-points" />} />
          <Route path="/all-points" element={<Filter
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          searchPlaceholder={t('searchPlaceholder')}
                                          dividerHeaderName={t('dividerHeaderName')}
                                          pageName1={t('shopPage')}
                                          pageName2={t('mtoPage')}
                                          pageName5={t('atmPage')}
                                          statement={t('redemptionPointsStatement')}
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                          bannerMessage={t('bannerMessage')}
                                          popUpTitle={t('popUpTitle')}
                                          name={AllRedemptionPoints}
                                          getDistricts={getDistrictsHavingAllPoints}
                                          getVillages={getVillagesHavingAllPoints}
                                          key={location.pathname}
                                      />} />
          <Route path="/mtos" element={<Filter
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          searchPlaceholder={t('searchPlaceholder')}
                                          dividerHeaderName={t('dividerHeaderName')}
                                          pageName1={t('shopPage')}
                                          pageName2={t('mtoPage')}
                                          pageName5={t('atmPage')}
                                          statement={t('mtosDisplayedStatement')}
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                          bannerMessage={t('bannerMessage')}
                                          popUpTitle={t('popUpTitle')}
                                          name={MTOs}
                                          getDistricts={getDistrictsHavingMTOs}
                                          getVillages={getVillagesHavingMTOs}
                                          key={location.pathname}
                                      />} />
          <Route path="/shops" element={<Filter 
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          searchPlaceholder={t('searchPlaceholder')}
                                          dividerHeaderName={t('dividerHeaderName')}
                                          pageName1={t('shopPage')}
                                          pageName2={t('mtoPage')}
                                          pageName5={t('atmPage')}
                                          statement={t('shopsDisplayedStatement')} 
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                          bannerMessage={t('bannerMessage')}
                                          popUpTitle={t('popUpTitle')}
                                          name={Shops}
                                          getDistricts={getDistrictsHavingShops}
                                          getVillages={getVillagesHavingShops}
                                          key={location.pathname}
                                      />} />
          <Route path="/essnMtos" element={<Filter
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          searchPlaceholder={t('searchPlaceholder')}
                                          dividerHeaderName={t('dividerHeaderName')}
                                          pageName1={t('shopPage')}
                                          pageName2={t('mtoPage')}
                                          pageName5={t('atmPage')}
                                          statement={t('essnMtosDisplayedStatement')}
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                          bannerMessage={t('bannerMessage')}
                                          popUpTitle={t('popUpTitle')}
                                          name={EssnMTOs}
                                          getDistricts={getDistrictsHavingEssnMTOs}
                                          getVillages={getVillagesHavingEssnMTOs}
                                          key={location.pathname}
                                      />} />
          <Route path="/sites" element={<Filter
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          searchPlaceholder={t('searchPlaceholder')}
                                          dividerHeaderName={t('dividerHeaderName')}
                                          pageName1={t('shopPage')}
                                          pageName2={t('mtoPage')}
                                          pageName5={t('atmPage')}
                                          statement={t('sitesDisplayedStatement')}
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                          bannerMessage={t('bannerMessage')}
                                          popUpTitle={t('popUpTitle')}
                                          name={Sites}
                                          getDistricts={getDistrictsHavingValidationSites}
                                          getVillages={getVillagesHavingValidationSites}
                                          key={location.pathname}
                                      />} />
          <Route path="/atms" element={<Filter
                                          placeHolderName1={t('placeHolder1')}  
                                          placeHolderName2={t('placeHolder2')}
                                          searchPlaceholder={t('searchPlaceholder')}
                                          dividerHeaderName={t('dividerHeaderName')}
                                          pageName1={t('shopPage')}
                                          pageName2={t('mtoPage')}
                                          pageName5={t('atmPage')}
                                          statement={t('atmsDisplayedStatement')}
                                          alertMessage={t('filteringMessage')}
                                          usedLanguage={lngUsed}
                                          dataLoad={t('dataLoad')}
                                          loading={t('loading')}
                                          location={t('location')}
                                          bannerMessage={t('bannerMessage')}
                                          popUpTitle={t('popUpTitle')}
                                          name={ATMs}
                                          getDistricts={getDistrictsHavingAtms}
                                          getVillages={getVillagesHavingAtms}
                                          key={location.pathname}
                                      />} />
      </Routes> 
    </div>
  );  
}

export default App;
