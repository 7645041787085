import React, { useEffect, useState } from "react";
import "../Common/Styling/Display.css";
import { getAllAtms, getAtmByDistrict, getAtmByVillage, getBaseUrl } from "../../api";
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactGA from "react-ga4";
import Accordion from 'react-bootstrap/Accordion';
import disabilityIcon from "../../images/disabilityIcon.png";
import phoneIcon from "../../images/phoneIcon.png";
import locationIcon from "../../images/locationIcon.png";
import openingIcon from "../../images/openingIcon.png";

export default function ATMs(props) {
  const { usedLanguage } = props;
  const [atms, setAtms] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAtms, setTotalAtms] = useState(0); 
  const [districtCode, setDistrictCode] = useState(null);

  const fetchData = async (reset) => {
    try {
      
      let page = 1;

      if(!reset) {
        page = currentPage;
      }

      let data = [];

      let atmsList = [];

      if(props.districtCode && !props.villagePcode) {
        atmsList = await getAtmByDistrict(props.districtCode, page, props.searchQuery);
      } else if(props.villagePcode) {
        atmsList = await getAtmByVillage(props.villagePcode, page, props.searchQuery);
      } else {
        atmsList = await getAllAtms(page, props.searchQuery);
      }

      if(atmsList.last_page > page)  {
        setCurrentPage(page + 1);
      } else {
        setHasMoreData(false);
      }

      if(reset) {
        data = atmsList.data;
      } else {
        data = [...atms, ...atmsList.data];
      }

      setTotalAtms(atmsList.total);
      setAtms(data);
      setDistrictCode(props.districtCode);
      
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);

    fetchData(true);
  }, [props.districtCode, props.villagePcode, props.searchQuery]);

  function getTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  function getAdminAreaTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  function getLocation(location, locale) {
    return location ? location[locale === 'en' ? 'name_en' : 'name_ar'] : '';
}

  const lengthStatment =`${props.statement} ${totalAtms}`;

  const handleLinkClick = (event, latitude, longitude) => {
  
    ReactGA.event({
        category: "ATMs Users", 
        action: "Clicked an ATM Location", 
        label: `Latitude: ${latitude}, Longitude: ${longitude}`, 
    });

  };
  
  return (

    
    <div className="container">      
    <div className="container-sub">
      <h5 className='num'>{lengthStatment}</h5>
      <InfiniteScroll
        dataLength={atms.length}
        next={fetchData}
        hasMore={hasMoreData}
        loader={<p className="load" >{props.loading}</p>}
        endMessage={<p className="load" >{props.dataLoad}</p>}
        >

      <Row className="rowStyle">
          {atms?.map((atm, index) => (
              <Accordion className="col-md-5">
                <Accordion.Item eventKey="0" className="cardStyle">
                    <Accordion.Header>
                      <Row className="cardHeader" >
                        <Col  xs={3} className="p-0">
                          <img src={getBaseUrl() + "storage/" + atm.image_path} className="img" alt="atm"/>
                        </Col>
                        <Col xs={9} className="p-0">
                          <h5 className="name">{getTranslation(atm.translations, usedLanguage, 'name')}</h5>
                        </Col>
                      </Row>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="disabilityDiv">
                        <p className="details">
                          <img alt="" src={locationIcon} className="icon"/>
                          {getAdminAreaTranslation(atm.district.translations, usedLanguage, 'name')}{usedLanguage === 'en' ? ',' : '،' }
                          {getAdminAreaTranslation(atm.village.translations, usedLanguage, 'name')}{usedLanguage === 'en' ? ',' : '،' }
                          {getTranslation(atm.translations, usedLanguage, 'address')}{' - '}
                          {getLocation(atm.location, usedLanguage)}
                        </p>
                        {atm.is_accessible === 1 && <img alt="" src={disabilityIcon} className="icon"/>}
                      </div>
                      <p className="details"><img alt="" src={openingIcon} className="icon"/>
                        {getTranslation(atm.translations, usedLanguage, 'working_hours')}
                      </p>
                      <p className="details"><img alt="" src={phoneIcon} className="icon"/>{atm.phone_number ? atm.phone_number : '-'}</p>
                    
                      <a  href={`https://www.google.com/maps/search/?api=1&query=${atm.latitude},${atm.longitude}`} 
                          target="_blank"
                          onClick={(e) => handleLinkClick(e, atm.latitude, atm.longitude)}
                      >
                        <div className="cardFooter">
                          <h6 className="buttonText">
                            {props.location}
                          </h6>
                        </div>
                    </a>
                    </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))} 
          </Row>
      </InfiniteScroll>
    </div>
    </div>
  );
}
