import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate   } from "react-router-dom";
import logo from "../images/wfp-logo-standard-white-en.png";
import "./Navbar.css";
import i18next from "i18next";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';

export default function NavBar(props) {
    const location = useLocation();
    
    let initialPath = '';

    if(location.pathname.startsWith('/shops')) {
        initialPath = 'shops';
    }

    if(location.pathname.startsWith('/mtos')) {
        initialPath = 'mtos';
    }

    if(location.pathname.startsWith('/essnMtos')) {
        initialPath = 'essnMtos';
    }

    if(location.pathname.startsWith('/sites')) {
        initialPath = 'sites';
    }

    if(location.pathname.startsWith('/atms')) {
        initialPath = 'atms';
    }
    if(location.pathname.startsWith('/all-points')) {
        initialPath = 'all-points';
    }

    const [activeTab, setActiveTab] = useState(initialPath);
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setShowOffcanvas(false);
    };

    const getHeaderText = () => {
        if (location.pathname.startsWith("/shops")) {
            return props.shopHeaderName;
        }
        if (location.pathname.startsWith("/mtos")) {
            return props.mtoHeaderName;
        }
        if (location.pathname.startsWith("/essnMtos")) {
            return props.essnMtoHaderName;
        }
        if (location.pathname.startsWith("/sites")) {
            return props.sitesHeaderName;
        }
        if (location.pathname.startsWith("/atms")) {
            return props.atmsHeaderName;
        }
        if (location.pathname.startsWith("/all-points")) {
            return props.redemptionPointsHeaderName;
        }
        return props.redemptionPointsHeaderName;
    };
    

    return (
    <div > 
        <Navbar className="bg-body-tertiary mb-3" expand={false} style={{ direction: props.usedLanguage === 'ar' ? 'rtl' : 'ltr' }}>
            <Container fluid className="navBarContainer">
                <Navbar.Brand className="navBarSmall">
                    <img alt="" src={logo} className="logo"/>
                </Navbar.Brand>
                <div className="sideDiv">
                    <ul className="translationList">
                        {Object.keys(props.lngs).map((lng) => (
                            <li  key={lng}>
                                <span 
                                    className="translation" 
                                    key={lng} onClick={() => props.changeLanguage(lng)} 
                                    disabled={i18next.resolvedLanguage === lng}
                                >
                                {props.lngs[lng].nativeName}
                                </span>
                            </li>
                        ))}
                    </ul>  
                    <Navbar.Toggle 
                                aria-controls={'offcanvasNavbar-expand-false'} 
                                className="custom-navbar-toggler"
                                onClick={() => setShowOffcanvas(true)}
                    />                       
                </div>
                <Navbar.Offcanvas
                    id={'offcanvasNavbar-expand-false'}
                    aria-labelledby={'offcanvasNavbarLabel-expand-false'}
                    placement={props.usedLanguage === 'ar' ? 'end' : 'start'}
                    show={showOffcanvas}
                    onHide={() => setShowOffcanvas(false)}
                    style={{ backgroundColor: "#0B77C2" }}
                >
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Link  to="/all-points" className = "leftButton" 
                            onClick={() => handleTabChange("all-points")}
                            style={{
                                backgroundColor: activeTab === "all-points" ? '#34A2D9' : '',
                            }}
                        >{props.pageName}
                        </Link>
                        <Link  to="/essnMtos" className = "leftButton" 
                            onClick={() => handleTabChange("essnMtos")}
                            style={{
                                backgroundColor: activeTab === "essnMtos" ? '#34A2D9' : '',
                            }}
                        >{props.pageName3}
                        </Link>
                        <Link  to="/sites" className = "leftButton" 
                            onClick={() => handleTabChange("sites")}
                            style={{
                                backgroundColor: activeTab === "sites" ? '#34A2D9' : '',
                            }}
                        >{props.pageName4}
                        </Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
        <h2 className="headerName">{getHeaderText()}</h2>
    </div>
    )
}