import React, { useEffect, useState } from "react";
import "../Common/Styling/Display.css";
import { getAllShops, getShopByDistrict, getShopByVillage, getBaseUrl } from "../../api";
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactGA from "react-ga4";
import phoneIcon from "../../images/phoneIcon.png";
import shopPromotion from "../../images/shopPromotion-img.png";
import locationIcon from "../../images/locationIcon.png";
import Accordion from 'react-bootstrap/Accordion';

export default function Shops(props) {
  const { usedLanguage } = props;
  const [shops, setShops] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalShops, setTotalShops] = useState(0); 
  const [districtCode, setDistrictCode] = useState(null);
  const isRTL = document.documentElement.dir === "rtl";

  const fetchData = async (reset) => {
    try {
      
      let page = 1;

      if(!reset) {
        page = currentPage;
      }

      let data = [];

      let shopsList = [];

      if(props.districtCode && !props.villagePcode) {
        shopsList = await getShopByDistrict(props.districtCode, page, props.searchQuery);
      } else if(props.villagePcode) {
        shopsList = await getShopByVillage(props.villagePcode, page, props.searchQuery);
      } else {
        shopsList = await getAllShops(page, props.searchQuery);
      }

      if(shopsList.last_page > page)  {
        setCurrentPage(page + 1);
      } else {
        setHasMoreData(false);
      }

      if(reset) {
        data = shopsList.data;
      } else {
        data = [...shops, ...shopsList.data];
      }

      setTotalShops(shopsList.total);
      setShops(data);
      setDistrictCode(props.districtCode);
      
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);

    fetchData(true);
  }, [props.districtCode, props.villagePcode, , props.searchQuery]);

  function getTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  function getPromotion(promotions, locale, field) {
    const promotion = promotions.find(t => t.locale === locale);
    return promotion ? promotion[field] : '';
  }

  function getAdminAreaTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  const lengthStatment =`${props.statement} ${totalShops}`;

  const handleLinkClick = (event, latitude, longitude) => {

    ReactGA.event({
        category: "Shops Users", 
        action: "Clicked a shop Location", 
        label: `Latitude: ${latitude}, Longitude: ${longitude}`, 
    });

  };
  
  return (

    
    <div className="container">      
    <div className="container-sub">
      <h5 className='num'>{lengthStatment}</h5>
      <InfiniteScroll
        dataLength={shops.length}
        next={fetchData}
        hasMore={hasMoreData}
        loader={<p className="load" >{props.loading}</p>}
        endMessage={<p className="load" >{props.dataLoad}</p>}
        >

        <Row className="rowStyle">
          {shops?.map((shop, index) => (
            <Accordion className="col-md-5">
              <Accordion.Item eventKey="0" className="cardStyle">
                  <Accordion.Header>
                    <Row className="cardHeader" >
                      <Col  xs={3} className="p-0">
                      <img 
                          src={shop.promotions && shop.promotions.length != 0 ? shopPromotion : getBaseUrl() + "storage/" + shop.image_path} 
                          className="img" 
                          alt="shop"
                        />
                      </Col>
                      <Col xs={9} className="p-0">
                        <h5 className="name">{getTranslation(shop.translations, usedLanguage, 'name')}</h5>
                      </Col>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Body>
                      <p className="details">
                        <img alt="" src={locationIcon} className="icon"/>
                        {getAdminAreaTranslation(shop.district.translations, usedLanguage, 'name')}{usedLanguage === 'en' ? ',' : '،' }
                        {getAdminAreaTranslation(shop.village.translations, usedLanguage, 'name')}{usedLanguage === 'en' ? ',' : '،' }
                        {getTranslation(shop.translations, usedLanguage, 'address')}
                      </p>                      
                      <p className="details"><img alt="" src={phoneIcon} className="icon"/>{shop.phone_number ? shop.phone_number : '-'}</p>

                      {shop.promotions && shop.promotions.length != 0 && (
                      <div className="promotionDiv">
                        <h7 className="popUpTitle">{props.popUpTitle}</h7>
                        {shop.promotions.map((promotion, promoIndex) => (
                        <Col key={promoIndex} className="promotionTxt">
                          <p>
                            {getPromotion(promotion.translations, usedLanguage, "promotion")}
                          </p>
                        </Col>
                      ))}
                      </div>
                    )}
                    <a  href={`https://www.google.com/maps/search/?api=1&query=${shop.latitude},${shop.longitude}`} 
                        target="_blank"
                        onClick={(e) => handleLinkClick(e, shop.latitude, shop.longitude)}
                    >
                      <div className="cardFooter">
                        <h6 className="buttonText">
                          {props.location}
                        </h6>
                      </div>
                  </a>
                  </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))} 
        </Row>
      </InfiniteScroll>
    </div>
    </div>
  );
}
